.App {
  text-align: center;
  font-family: 'Victor Mono';
  font-size: 18px;
  background-color: #2b332f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
  width: 260px;
}

.App-header h1 {
  font-style: italic;
  font-size: 3rem;
}

.App-header h1, .App-header p {
  margin: 0;
}

.App-header p {
  font-size: 1.2rem;
  text-align: justify;
}

.contacts {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  width: 100%;
}

.clients {
  display: flex;
  font-size: 1.1rem;
  width: 100%;
}

a {
  color: #d2d2d2;
  text-decoration: underline;
  font-style: italic;
  font-size: 2.1rem;
}

a:hover {
  color: #fff;
  cursor: pointer;
}

@media (min-width:600px)  {
  .App-header {
    width: 440px;
  }

  .App-header h1 {
    font-size: 5rem;
  }

  .App-header p {
    font-size: 1.2rem;
    margin-bottom:  16px;
  }

  .contacts {
    font-size: 1.2rem;
  }

}
